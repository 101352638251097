import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
// MUI
import {
    Container,
    Paper,
    Grid,
    List,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    ListSubheader,
    Hidden
} from '@mui/material';
// COMPONENTS
import SettingsProfileTab from './tabs/SettingsProfileTab';
import SettingsSecurityTab from "./tabs/SettingsSecurityTab";
import SettingsBillingTab from "./tabs/SettingsBillingTab";
import SettingsOrganisationsTab from "./tabs/SettingsOrganisationsTab";
import SettingsDomainsTab from "./tabs/SettingsDomainsTab";
// MUI ICONS
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DnsIcon from '@mui/icons-material/Dns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {VpnKey} from "@mui/icons-material";
// MODULES
import {useTranslation} from "react-i18next";
// AWS
import SettingsApiKeysTab from "./tabs/SettingsApiKeysTab";
// error management
import {useSnackbar} from "@hooks";

const useStyles = {
    paper: {
        paddingBottom: '8px',
        marginBottom: 5,
        minHeight: '80vh',
        borderRadius: 5
    },
    subheader: {backgroundColor: 'white'},
    secondary: {color: '#6192bd'}
};

export default function Settings() {

    // Path detection
    const location = useLocation();
    // Error management
    const {handleSuccess, handleError} = useSnackbar()

    const classes = useStyles;
    const {t} = useTranslation(['settings', 'common']);

    const [activePage, setActivePage] = useState<null | string>("profile");

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        navigateToTab(query)
    }, [location]);

    const navigateToTab = (query: any) => {
        if (query.has("profile")) {
            setActivePage("profile");
            return;
        }
        if (query.has("billing")) {
            setActivePage("billing");
            return;
        }
        if (query.has("usage")) {
            setActivePage("billing");
            return;
        }
        if (query.has("security")) {
            setActivePage("security");
            return;
        }
        if (query.has("domains")) {
            setActivePage("domains");
            return;
        }
        if (query.has("organizations")) {
            setActivePage("organisations");
            return;
        }
        if (query.has("organisations")) {
            setActivePage("organisations");
            return;
        }
        // if (query.has("support")) { setActivePage("support"); return; }
        // if (query.has("new-support-ticket")) { setActivePage("support"); return; }
    }

    const handleActivePage = (event: React.MouseEvent<HTMLElement>) => {
        if (activePage !== event.currentTarget.id) {
            setActivePage(event.currentTarget.id);
        }
    };

    const renderActivePage = () => {
        switch (activePage) {
            case 'profile': return (<SettingsProfileTab {...{handleSuccess, handleError}} />);
            case 'billing': return (<SettingsBillingTab {...{handleSuccess, handleError}} />);
            case 'security': return (<SettingsSecurityTab {...{handleSuccess, handleError}} />);
            case 'organisations': return (<SettingsOrganisationsTab {...{handleSuccess, handleError}} />);
            case 'domains': return (<SettingsDomainsTab {...{handleSuccess, handleError}} />);
            case 'api': return (<SettingsApiKeysTab {...{handleSuccess, handleError}} />);
            case 'default' : return (<SettingsProfileTab {...{handleSuccess, handleError}} />);
            default: return (<SettingsProfileTab {...{handleSuccess, handleError}} />);
            // case 'support' : { return (<SupportComponent />); }
        }
    }

    return (
        <main>
            <Container maxWidth="lg" style={{padding: "32px 0px 32px 0px"}}>
                <Paper variant="outlined" sx={classes.paper}>
                    <Grid container alignItems="flex-start" justifyContent="space-between">
                        <Grid item xs={2} md={3}>
                            <List sx={{ pt: 0 }}>
                                <ListItemButton component={Link} to="/" sx={{ borderRadius: "20px 0 0 0"}}>
                                    <ListItemIcon><ArrowBackIcon color="secondary"/></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={t("Back")}
                                                                 sx={classes.secondary}/></Hidden>
                                </ListItemButton>

                                <Hidden smDown><ListSubheader
                                    sx={classes.subheader}>{t("Settings_global")}</ListSubheader></Hidden>

                                <ListItemButton id="profile" onClick={handleActivePage}>
                                    <ListItemIcon><PersonIcon/></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={t("Profile")}/></Hidden>
                                </ListItemButton>
                                <ListItemButton id="security" onClick={handleActivePage}>
                                    <ListItemIcon><LockIcon/></ListItemIcon>
                                    <Hidden smDown> <ListItemText primary={t("Account_security")}/></Hidden>
                                </ListItemButton>
                                <ListItemButton id="billing" onClick={handleActivePage}>
                                    <ListItemIcon><PaymentIcon/></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={t("Billing_Plans")}/></Hidden>
                                </ListItemButton>


                                <Hidden smDown><ListSubheader
                                    sx={classes.subheader}>{t("Settings_advanced")}</ListSubheader></Hidden>

                                <ListItemButton id="organisations" onClick={handleActivePage}>
                                    <ListItemIcon><GroupWorkIcon/></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={t("Organizations_and_users")}/></Hidden>
                                </ListItemButton>
                                <ListItemButton id="domains" onClick={handleActivePage}>
                                    <ListItemIcon><DnsIcon/></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={t("Domain_names")}/></Hidden>
                                </ListItemButton>
                                <ListItemButton id="api" onClick={handleActivePage}>
                                    <ListItemIcon><VpnKey /></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={"API"}/></Hidden>
                                </ListItemButton>

                                {/* <Hidden smDown><ListSubheader sx={classes.subheader}>{t("Support")}</ListSubheader></Hidden>

                                <ListItemButton id="support" onClick={handleActivePage}>
                                    <ListItemIcon><LiveHelp /></ListItemIcon>
                                    <Hidden smDown><ListItemText primary={t("Tickets")} /></Hidden>
                                </ListItemButton> */}

                            </List>
                        </Grid>
                        <Grid item xs={10} sm={10} md={9}>
                            <div>{renderActivePage()}</div>
                        </Grid>
                    </Grid>

                </Paper>
            </Container>
        </main>
    );
}

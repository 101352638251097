import React from "react"
import {Chip} from "@mui/material";

export default function CampaignChip(props: any) {

    const {value, color, small, onClick, outlined} = props

    if (!value) return <></>

    let backgroundColor: string;
    let textColor: string;

    switch (color) {
        case 'green':
            backgroundColor = '#c8e6c9';
            textColor = '#256029';
            break;
        case 'purple':
            backgroundColor = '#eccfff';
            textColor = '#694382';
            break;
        case '#673ab7':
            backgroundColor = '#eccfff';
            textColor = '#694382';
            break;
        case 'red':
            backgroundColor = '#ffcdd2';
            textColor = '#c63737';
            break;
        case 'orange':
            backgroundColor = '#ffd8b2';
            textColor = '#805b36';
            break;
        case 'blue':
            backgroundColor = '#b3e5fc';
            textColor = '#23547b';
            break;
        case '#2196f3':
            backgroundColor = '#b3e5fc';
            textColor = '#23547b';
            break;
        case 'yellow':
            backgroundColor = '#feedaf';
            textColor = '#8a5340';
            break;
        case '#f44336':
            backgroundColor = '#ffcdd2';
            textColor = '#c63737';
            break;
        default:
            backgroundColor = '#cdcccc';
            textColor = '#5e5e5e';
            break;
    }

    return (
        <Chip
            label={value}
            onClick={onClick ? onClick : () => { return }}
            clickable={Boolean(onClick)}
            size={small ? "small" : "medium"}
            style={{
                backgroundColor: outlined ? "transparent" : backgroundColor,
                color: textColor,
                textTransform: 'uppercase',
                fontWeight: 700,
                letterSpacing: '.3px',
                fontSize: '12px',
            }}
        />
    )
}
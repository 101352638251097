import React, {useState, useEffect} from "react"
import {Link as RouterLink, useHistory} from "react-router-dom";
import ReactCodeInput from "react-code-input";
// hooks & translation
import {useUser} from "@context";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useSnackbar} from "@hooks";
// MUI
import {
    Alert,
    Box, CircularProgress,
    Container, Divider,
    FormControl,
    Grid,
    InputAdornment,
    Link,
    Paper, Stack,
    TextField,
    Typography
} from "@mui/material";
import {AccountCircle, LoginRounded} from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
// AWS
import {Auth} from "aws-amplify";
// Assets
import Background from "@assets/img/background_login.webp";
// Custom components
import {Button} from "@components";
import {
    goldSubscription,
    platinumSubscription,
    standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";

let creds = {
    username: "",
    password: "",
    passwordRepeated: "",
};

export default function Login(props: any) {
    const history = useHistory();
    const {setCredentials} = useUser()

    const {handleError} = useSnackbar()
    const {t} = useTranslation(['common']);

    // Custom SSO
    // const bouyguesLoginUrl = "https://unitag.auth.eu-west-1.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=Bouygues&client_id=4lbirb1cci5fe4b5i2e2giifc2&redirect_uri=https://bouygues.console.unitag.io/bouygues_sso&scope=email+openid"

    // loading
    const [loading, setLoading] = useState(false);
    // 2FA
    const [open2FA, setOpen2FA] = useState<boolean>(false);
    const [tmpClient, setTmpClient] = useState<any | null>(null);
    const [tokenValid, setTokenValid] = useState(false);
    const [totpChecked, setTotpChecked] = useState(false);
    // SSO
    const [sso, setSso] = useState<null | { provider: string, logo: string } >(null)

    // styles
    const styles = {
        container: {
            minHeight: "100vh",
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            px: "0!important"
        },
        logoGridItem: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        mainPaper: {
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },
        logo: {
            height: (!!sso && !!sso.provider) ? 175 : 110,
            width: "auto",
            margin: 32,
            filter: (!!sso && !!sso.provider) ? "" : "drop-shadow(0 0 0.75rem #afc928)"
        },
        title: {
            marginBottom: '32px'
        },
        subactions: {
            my: 2
        },
        translationBox: {
            padding: '24px',
            textAlign: 'center',
        },
        circularProgress: {
            m: 1
        },
        codePaper: {
            p: 2,
            my: 2
        },
        enterCode: {
            fontWeight: 700,
            color: '#4f566b',
            mt:1
        }
    }

    useEffect(() => {
        let path = window.location.href

        switch (true) {
            case path.includes("bouygues"):
            case path.includes("losinger-marazzi"):
                setSso({ provider: "Bouygues", logo: "bouygues.png" })
                break;
            case path.includes("sisley"):
                setSso({ provider: "Sisley", logo: "sisley.png" })
                break;
            case path.includes("bonduelle"):
                setSso({ provider: "Bonduelle", logo: "bonduelle.png" })
                break;
            case path.includes("adeo"):
                setSso({ provider: "Adeo", logo: "Adeo.png" })
                break;
            case path.includes("equans"):
                setSso({ provider: "Equans", logo: "equans.jpg" })
                break;
            case path.includes("roullier"):
                setSso({ provider: "Roullier", logo: "roullier.jpg" })
                break;
            default:
                return;
        }
    }, [])

    // Redirection logic
    const redirectByPath = () => {
        switch (props.product) {
            case "vcards":
                history.push("/applications/vcard/control_panel");
                break;
            case "buy-vcards":
                history.push({ pathname: "/alacarte", state: { fromPlan: standaloneSubscription, topUp: true } });
                break;
            case "plans":
                history.push("/plans");
                break;
            case "standard-plan":
                history.push({ pathname: '/alacarte', state: {fromPlan: standardSubscription} });
                break;
            case "gold-plan":
                history.push({ pathname: '/alacarte', state: {fromPlan: goldSubscription} });
                break;
            case "platinum-plan":
                history.push({ pathname: '/alacarte', state: {fromPlan: platinumSubscription} });
                break;
            case "":
                history.push("/");
                break;
            default:
                history.push("/");
        }
    }

    // Login procession logic
    const login = (e: any) => {
        e.preventDefault()
        // Start Circular Progress on Button
        setLoading(true);

        Auth.signIn(creds.username, creds.password).then(value => {
            if (value.challengeName !== undefined) {
                if (value.challengeName === "NEW_PASSWORD_REQUIRED") {
                    Auth.completeNewPassword(
                        value,
                        creds.password,
                        {
                            email: creds.username,
                        }
                    ).then(value => {
                        setCredentials({
                            refresh_token: "",
                            id_token: value.signInUserSession.idToken.jwtToken,
                        });

                        redirectByPath();
                    });
                } else if (value.challengeName === "SOFTWARE_TOKEN_MFA") {
                    // Handle 2FA, present 2FA dialog
                    setTmpClient(value);
                    setOpen2FA(true)
                }
            } else {
                setCredentials({
                    refresh_token: "",
                    id_token: value.signInUserSession.idToken.jwtToken,
                });
                redirectByPath();
            }
        }, reason => {
            handleError(`${t(reason.message)}`)
            setLoading(false);
        });
    }

    // 2FA
    const renderMFAStep = () => {

        const handleMFACodeChange = (code: string) => {

            if (code.length < 6 || tmpClient === null) return undefined

            Auth.confirmSignIn(tmpClient, code, 'SOFTWARE_TOKEN_MFA')
                .then(value => {
                    setCredentials({
                        refresh_token: "",
                        id_token: value.signInUserSession.idToken.jwtToken,
                    });

                    Auth.rememberDevice().then();
                    redirectByPath();
                }, reason => {

                    if (reason.message === "Invalid device key given.") {
                        Auth.currentUserPoolUser().then(user => {
                            user.getCachedDeviceKeyAndPassword();
                            Auth.rememberDevice().then();
                        })

                        redirectByPath();
                    } else {

                        setTotpChecked(true);
                        setTokenValid(false);

                        handleError(`${t(reason.message)}`)
                        setLoading(false);
                    }
                })
        }

        return (
            <>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        <Typography variant="h4" align="center">
                            {t("Login_2fa_title")}
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        <Stack justifyContent="center" alignItems="center">
                            <Typography variant='body1' align={"center"}>
                                {t("Login_2fa_intro")}
                            </Typography>
                            <Paper variant={'outlined'} sx={styles.codePaper}>
                                <ReactCodeInput
                                    type='text'
                                    fields={6}
                                    onChange={(e) => handleMFACodeChange(e)}
                                    name="verificationCodeInput"
                                    inputMode={'latin'}
                                />
                                <Typography variant={'body1'} sx={styles.enterCode} align={"center"}>
                                    {t("Login_2fa_enter_code")}
                                </Typography>
                            </Paper>
                            {(totpChecked && !tokenValid) && <Alert severity={'error'}>{t("Login_2fa_error")}</Alert>}
                        </Stack>
                    </Grid>
                </Grid>
            </>
        )
    }

    // Layout content
    const renderLayout = () => {

        const returnLogoSrc = () => {
            if (!!sso && !!sso.logo) return `/modules/${sso.logo}`
            return "/assets/logo-unitag.svg"
        }

        const emailOnChange = (e: any) => {
            creds.username = e.target.value
            if (creds.username.includes('bouygues-construction') || creds.username.includes("losinger-marazzi.ch")) {
                window.location.replace('https://bouygues.console.unitag.io')
                return
            } else if (creds.username.includes('@sisley')) {
                window.location.replace('https://sisley.console.unitag.io')
                return
            } else if (creds.username.includes('@bonduelle')) {
                window.location.replace('https://bonduelle.console.unitag.io')
                return
            } else if (creds.username.includes('@adeo')) {
                window.location.replace('https://adeo.console.unitag.io')
                return
            } else if (creds.username.includes('@equans')) {
                window.location.replace('https://equans.console.unitag.io')
                return
            } else if (
                creds.username.includes('@maison-colibri.com') ||
                creds.username.includes('@nuwen.com') ||
                creds.username.includes('@patisseriesgourmandes.com') ||
                creds.username.includes('@phosphea.com') ||
                creds.username.includes('@roullier.com') ||
                creds.username.includes('@timacagro.com')
            ) {
                window.location.replace('https://roullier.console.unitag.io')
                return
            }
        }

        const renderLoginForm = () => {
            if (!!sso && !!sso.provider) return <></>
            return (
                <>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label={t("Mail")}
                            id="email"
                            placeholder={t("Your_mail")}
                            onChange={emailOnChange}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>

                    <br/>
                    <br/>

                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label={t("Password")}
                            type="password"
                            id="password"
                            onChange={(e) => creds.password = e.target.value}
                            placeholder={t("Your_password")}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </>
            )
        }

        const renderSubActions = () => {
            if (!!sso && !!sso.provider) return <></>
            return (
                <>
                    <Grid container sx={styles.subactions} justifyContent={{ xs: "center", md: "space-between" }}>
                        <Grid item>
                            <Link
                                component={RouterLink}
                                to={`/signup${props.product ? "?product=" + props.product : ""}`}
                                color="secondary"
                            >
                                {t("Signup")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link component={RouterLink} to="/forgot_password" color="secondary">
                                {t("Forgot_password")}
                            </Link>
                        </Grid>
                    </Grid>
                </>
            )
        }

        const renderActions = () => {
            if (!!sso && !!sso.provider) {
                return (
                    <Button
                        primary
                        large
                        fullWidth
                        startIcon={<LoginRounded />}
                        onClick={()=> Auth.federatedSignIn({customProvider: sso.provider})}
                    >
                        {t("Login")}
                    </Button>
                )
            }

            return (
                <Button primary fullWidth type="submit" disabled={loading}>
                    {loading ? <CircularProgress size={21} sx={styles.circularProgress}/> : t("Login")}
                </Button>
            )
        }

        const renderTranslationSection = () => {

            const toggleLang = (e: any) => {
                let lng = e.target.value
                i18next.changeLanguage(lng, (err, t) => {
                    if (err) handleError(`${err}`);
                    t('key');
                }).then();
            }

            return (
                <Box sx={styles.translationBox}>
                    <Button text small value={"fr-FR"} onClick={toggleLang}>Français</Button>&nbsp;&nbsp;
                    <Button text small value={"en-EN"} onClick={toggleLang}>English</Button>&nbsp;&nbsp;
                    <Button text small value={"es-ES"} onClick={toggleLang}>Español</Button>
                </Box>
            )
        }

        // Decide whether to use login form or 2FA form
        const renderProperLoginMethod = () => {
            if (open2FA) return renderMFAStep()
            return (
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        <Typography variant="h4" sx={styles.title} align="center">
                            {t("Login_to_unitag")}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        <form onSubmit={(e) => login(e)}>
                            {renderLoginForm()}
                            {renderSubActions()}
                            {renderActions()}
                        </form>
                    </Grid>
                    <Grid item xs={10} sm={8} md={9} lg={7}>
                        {renderTranslationSection()}
                    </Grid>
                </Grid>
            )
        }

        return (
            <Container maxWidth={false} sx={styles.container}>
                <Grid container justifyContent={"center"} spacing={3}>
                    <Grid item md={6} sx={styles.logoGridItem}>
                        <img
                            draggable={false}
                            src={returnLogoSrc()}
                            alt={"login logo"}
                            height={200} width={450}
                            style={styles.logo}
                        />
                    </Grid>
                    <Grid item xs={12} sm={11} md={6}>
                        <Paper elevation={0} sx={styles.mainPaper}>
                            {renderProperLoginMethod()}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        )
    }

    return <main>{renderLayout()}</main>
}

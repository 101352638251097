import React, {Suspense, useEffect} from 'react';
import ReactCodeInput from "react-code-input";
import {useHistory, useLocation, Link as RouterLink} from "react-router-dom";
// MUI
import {
    Box,
    Backdrop,
    CircularProgress,
    Container,
    FormControl,
    Grid, IconButton,
    InputAdornment, InputLabel,
    Link, OutlinedInput,
    Paper,
    TextField,
    Typography,
    Alert
} from '@mui/material';

// MUI ICONS
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {AccountCircle, CheckCircle, RemoveCircle, Visibility, VisibilityOff} from "@mui/icons-material";
// ASSETS
import Background from '@assets/img/background_login.webp';
import {Amplify, Auth} from 'aws-amplify';
// TRANSLATION
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
// Unitag UI
import {Button} from '@components';


// OTHER
import {AlertColor} from "@mui/material/Alert/Alert";
import {useSnackbar} from "@hooks";


const {
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_COGNITO_APP_ID,
    REACT_APP_ENV,
} = process.env;


Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        userPoolId: REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: REACT_APP_COGNITO_APP_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
});

const useStyles = {
    content: {
        // alignContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        padding: '24px',
    },
    passwordRule: {
        marginLeft: '8px',
        fontWeight: 300,
        fontSize: '14px',
        color: '#676767',
    },
    passwordRuleOK: {
        marginLeft: '8px',
        fontWeight: 300,
        fontSize: '14px',
        color: '#b4c74a',
    },
    gridSigninLeft: {
        marginLeft: '24px',
        textAlign: "left",
    },
    textField: {
        marginLeft: '24px',
        marginRight: '24px',
    },
    buttonAction: {
        paddingLeft: '24px',
        paddingRight: '24px',
    },
    buttonActionWrapper: {
        textAlign: 'center',
        marginLeft: '24px',
        marginRight: '24px',
    }
};

let creds = {
    username: "",
    password: "",
    verificationCode: "",
};

function Login() {

    const classes = useStyles;
    const history = useHistory();
    const {handleError} = useSnackbar()

    const [isRegistering, setIsRegistering] = React.useState<null | boolean>(false);
    type AnAlert = {
        message: string;
        variant: AlertColor;
    };
    const [activeAlert, setActiveAlert] = React.useState<null | AnAlert>(null);

    const {t} = useTranslation(['common']);
    const toggleLang = (lng: string) => {
        i18next.changeLanguage(lng, (_err, t) => {
            t('key');
        }).then();
    }


    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setPassword] = React.useState("");

    const [verificationCode, setVerificationCode] = React.useState("");

    const [step, setStep] = React.useState("email_input");

    const [passRuleLength, setPassRuleLength] = React.useState(false);
    const [passRuleUpper, setPassRuleUpper] = React.useState(false);
    const [passRuleLower, setPassRuleLower] = React.useState(false);
    const [passRuleNumber, setPassRuleNumber] = React.useState(false);
    const [passRuleChar, setPassRuleChar] = React.useState(false);
    const [canResetPassword, setCanResetPassword] = React.useState(false);

    React.useEffect(() => {

        if (verificationCode.length < 6) {
            return undefined;
        }

        setStep("password_input");
        setActiveAlert(null);

    }, [verificationCode]);

    React.useEffect(() => {

        if (password.length >= 8) {
            setPassRuleLength(true);
        } else {
            setPassRuleLength(false);
        }

        if (password.toLowerCase() !== password) {
            setPassRuleUpper(true);
        } else {
            setPassRuleUpper(false);
        }

        if (password.toUpperCase() !== password) {
            setPassRuleLower(true);
        } else {
            setPassRuleLower(false);
        }

        if (/\d/.test(password)) {
            setPassRuleNumber(true);
        } else {
            setPassRuleNumber(false);
        }

        const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (format.test(password)) {
            setPassRuleChar(true);
        } else {
            setPassRuleChar(false);
        }

        if (passRuleLower && passRuleLength && passRuleChar && passRuleNumber && passRuleUpper) {
            setCanResetPassword(true);
        } else {
            setCanResetPassword(false);
        }

    }, [password, passRuleLower, passRuleLength, passRuleChar, passRuleNumber, passRuleUpper]);

    const handleIsRegistering = () => {
        setIsRegistering(!isRegistering);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const renderAlert = () => {
        if (activeAlert !== null && !!activeAlert.message) {
            const {message, variant} = activeAlert;
            return (
                <Grid>
                    <Alert variant="outlined" severity={variant}>
                        {message}
                    </Alert>

                    <br/>
                    <br/>
                </Grid>

            )
        }
        return null
    }

    const renderTitle = () => {
        if (!isRegistering) {
            return (
                <Typography variant="h4" sx={classes.textField} align="center">
                    {t("Reset_password")}
                </Typography>
            )
        }
        return (
            <Typography variant="h4" sx={classes.textField} align="center">
                {t("Signup")}
            </Typography>
        )
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleChange = (event: any) => {

        switch (event.target.id) {
            case "email":
                creds.username = event.target.value;
                break;
            case "password":
                creds.password = event.target.value;
                break;
        }
    }

    const handleButtonClick = () => {

        switch (step) {
            case "email_input":

                Auth.forgotPassword(creds.username).then(() => {

                    setActiveAlert({
                        variant: "success",
                        message: `${t("Verification_code_sent")}`,
                    })

                    setStep("code_input");

                }).catch(err => {
                    if (err.code === "UserNotFoundException") {
                        handleError(t("error_user_not_found"))
                        setActiveAlert({
                            variant: "error",
                            message: `${t("Account_not_found")}`,
                        });
                    } else {
                        handleError(t("unknown_error"))
                        setActiveAlert({
                            variant: "error",
                            message: err.message,
                        });
                    }

                });

                break;
            case "code_input":
                setStep("password_input");
                setActiveAlert(null);
                break;
            case "password_input":


                Auth.forgotPasswordSubmit(creds.username, verificationCode, password)
                    .then(() => {
                        setActiveAlert({
                            variant: "success",
                            message: `${t("Password_update_success")}`
                        });
                        Auth.signOut({ global: true }).then()
                        history.push("/login");

                    })
                    .catch(err => {
                        handleError(t("error_sending_password_reinitialisation"))
                        setActiveAlert({
                            variant: "error",
                            message: err.message,
                        });

                    });
        }
    }

    const renderBtn = () => {

        let btnText = "";

        switch (step) {
            case "email_input":
                btnText = `${t("Reset_password_button")}`;
                break;
            case "code_input":
                btnText = `${t("Reset_password_button")}`;
                break;

            case "password_input":
                return (
                    <Grid sx={classes.buttonActionWrapper}>
                        <Button disabled={!canResetPassword} outlined primary fullWidth type="submit" onClick={handleButtonClick}>
                            {t("Reset_password")}
                        </Button>
                    </Grid>
                )
        }

        return (
            <Grid sx={classes.buttonActionWrapper}>
                <Button outlined primary fullWidth type="submit"
                        onClick={handleButtonClick}>
                    {btnText}
                </Button>
            </Grid>
        )
    }

    const handleVerificationCodeChange = (code: string) => {
        setVerificationCode(code);
    }

    const renderInputs = () => {

        switch (step) {
            case "email_input":

                return (
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label={t("Mail")}
                            id="email"
                            placeholder={t("Your_mail")}
                            sx={classes.textField}
                            onChange={handleChange}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                )

            case "code_input":

                return (

                    <Grid container direction="column" alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <ReactCodeInput type='text' fields={6} onChange={handleVerificationCodeChange}
                                            name="verificationCodeInput" inputMode={'latin'}/>
                        </Grid>
                    </Grid>

                )

            case "password_input":

                return (

                    <Grid container direction="row" spacing={3} justifyContent="flex-start" alignItems="center">
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="password" style={{
                                    marginLeft: '25px'
                                }}>{t("Password")}</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    placeholder={t("Your new password")}
                                    sx={classes.textField}
                                    onChange={handleChangePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Grid container sx={classes.textField}>
                                <Grid item>
                                    {passRuleLength ?
                                        <CheckCircle fontSize={"small"} style={{color: '#b4c74a'}}/>
                                        :
                                        <RemoveCircle fontSize={"small"} style={{color: '#676767'}}/>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={passRuleLength ? classes.passwordRuleOK : classes.passwordRule}>{t("Password_rule_8char")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={classes.textField}>
                                <Grid item>
                                    {passRuleLower ?
                                        <CheckCircle fontSize={"small"} style={{color: '#b4c74a'}}/>
                                        :
                                        <RemoveCircle fontSize={"small"} style={{color: '#676767'}}/>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={passRuleLower ? classes.passwordRuleOK : classes.passwordRule}>{t("Password_rule_1lwrcase")}</Typography>

                                </Grid>
                            </Grid>
                            <Grid container sx={classes.textField}>
                                <Grid item>
                                    {passRuleUpper ?
                                        <CheckCircle fontSize={"small"} style={{color: '#b4c74a'}}/>
                                        :
                                        <RemoveCircle fontSize={"small"} style={{color: '#676767'}}/>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={passRuleUpper ? classes.passwordRuleOK : classes.passwordRule}>{t("Password_rule_1uprcase")}</Typography>

                                </Grid>
                            </Grid>
                            <Grid container sx={classes.textField}>
                                <Grid item>
                                    {passRuleNumber ?
                                        <CheckCircle fontSize={"small"} style={{color: '#b4c74a'}}/>
                                        :
                                        <RemoveCircle fontSize={"small"} style={{color: '#676767'}}/>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={passRuleNumber ? classes.passwordRuleOK : classes.passwordRule}>{t("Password_rule_1number")}</Typography>

                                </Grid>
                            </Grid>
                            <Grid container sx={classes.textField}>
                                <Grid item>
                                    {passRuleChar ?
                                        <CheckCircle fontSize={"small"} style={{color: '#b4c74a'}}/>
                                        :
                                        <RemoveCircle fontSize={"small"} style={{color: '#676767'}}/>
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography
                                        sx={passRuleChar ? classes.passwordRuleOK : classes.passwordRule}>{t("Password_rule_1spchar")}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )

        }
    }

    return (
        <Container sx={classes.content}>

            {REACT_APP_ENV === "sandbox" ?
                <Grid style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 99999,
                    width: '100%',
                    height: '23px',
                    textAlign: 'center',
                    backgroundColor: "#fd5757",
                    color: "#ffffff",
                }}>
                    You are using the Sandbox environment, please read our documentation to understand its requirements
                    and limitations
                </Grid> :
                <Grid>

                </Grid>
            }

            <Grid style={
                {
                    "position": "absolute",
                    "height": "100%",
                    "top": "0",
                    "bottom": "0",
                    "left": "0",
                    "right": "0",
                    backgroundImage: `url(${Background})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    "overflowY": "auto"
                }
            }>
                <Container maxWidth="sm" style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }}>
                    <Paper elevation={5} style={{borderRadius: '16px', border: "1px solid #e0e0e0"}}>

                        <Box style={{padding: '24px'}}>
                            <Box style={{marginBottom: '16px'}}>{renderTitle()}</Box>

                            {
                                step === "code_input" || step === "password_input" ?
                                    null
                                    :
                                    <Typography variant="body1">
                                        {t("Reset_password_text")}
                                    </Typography>
                            }

                            <Box style={{marginTop: '24px'}}>{renderInputs()}</Box>

                            <Grid container style={{margin: "24px 0px"}}>
                                <Grid item xs={6}>
                                    {isRegistering ?
                                        <Link href="@/Views/Authentication/ForgotPassword/ForgotPassword#" onClick={handleIsRegistering} sx={classes.gridSigninLeft}>
                                            {t("Already_have_an_account")}
                                        </Link>
                                        : step === "code_input" || step === "password_input" || step === "email_input" ?
                                            null
                                            :
                                            <Link component={RouterLink} to="/signup" color="secondary"
                                                  sx={classes.gridSigninLeft}>
                                                {t("Signup")}
                                            </Link>
                                    }
                                </Grid>
                            </Grid>


                            {renderAlert()}
                            {renderBtn()}

                            <Box mx={3}>
                                <Button fullWidth secondary text component={RouterLink} to="/login"
                                        startIcon={<ArrowBackIcon/>}>{t("Back")}</Button>
                            </Box>

                        </Box>
                        <Box style={{
                            margin: "8px 1px 1px 1px",
                            backgroundColor: '#e0e0e0',
                            padding: '24px',
                            borderBottomRightRadius: '14px',
                            borderBottomLeftRadius: '14px',
                            textAlign: 'center',
                            color: 'grey'
                        }}>
                            <Button small text onClick={() => {
                                toggleLang('fr-FR')
                            }}>Français</Button>&nbsp;&nbsp;
                            <Button small text onClick={() => {
                                toggleLang('en-EN')
                            }}>English</Button>&nbsp;&nbsp;
                            <Button text small onClick={() => {
                                toggleLang('es-ES')
                            }}>Español</Button>
                        </Box>

                    </Paper>
                </Container>
            </Grid>

        </Container>
    )

}

function Loader() {
    return (
        <Backdrop style={{
            color: "white"
        }} open={true}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    )
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function ForgotPasswordComponent() {

    let query = useQuery();
    const history = useHistory();


    const {t} = useTranslation(["common"])
    const {handleError} = useSnackbar()

    useEffect(() => {
        const eid = query.get("eid");

        if (eid !== undefined && eid !== null) {
            history.push("/signup");
        }

        Auth.currentSession().then((sess) => {
            sess.getIdToken();

            // eslint-disable-next-line no-restricted-globals
            history.push("/");
            // setActiveModule(<Dashboard/>);

        }, () => {
            handleError(t("unknown_error"))
            // setActiveModule(<Login onChange={reloadWindow}/>);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Suspense fallback={<Loader/>}>
            <Login/>
        </Suspense>
    )
}

// ROUTING ROUTINES
// Sending users here:
// // // history.push({ pathname: "/alacarte", state: { fromPlan: user.account_type, topUp: true } })
// If only here to buy credits :
// // // Do NOT change the `fromPlan` (must be the same as the actual account type)
// // // Turn `topUp` true
// The other case (for directly upgrading/buying) can be seen in the /plans page

// REACT
import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
// MUI
import {
    Grid,
    Container,
    Paper,
    Typography,
    Collapse,
    useMediaQuery, Divider, Breadcrumbs, Link as MaterialLink, Stack, Switch, Tooltip
} from '@mui/material';
// Unitag Components
import {PlatinumPlan} from "./components/Platinum";
import {GoldPlan} from './components/Gold';
import {StandardPlan} from "./components/Standard";
import {APIPost} from "@api";
import {
    CheckoutSubscription,
    CreateCheckoutSessionRequest,
    CreateCheckoutSessionResponse,
} from "@interfaces";
import {PaymentDialog} from "./components/PaymentDialog";
import {useTranslation} from "react-i18next";
import {useStripe} from "@stripe/react-stripe-js";
import {
    extraDomainsSubscription,
    extraGoldUsersSubscription,
    extraPlatinumUsersSubscription, extraVcardsProductSubscription,
    extraVCardsSubscription, freeSubscription,
    goldSubscription,
    platinumSubscription, standaloneSubscription,
    standardSubscription
} from "@/constants/plans_en";
// Unitag UI
import {Button} from '@components';
import VCardsIcon from "@assets/img/icons/vcards-blue.png";
import VCardsProductImage from "@assets/img/alacarte/alacarte-vcard-product.png"
import VCardsImage from "@assets/img/alacarte/alacarte-vcard.png"
import {returnFractionFromDiscountPercentage, roundToCent} from "@functions";
import AlacarteModule from "@/Views/Alacarte/_components/AlacarteModule";
import {useUser} from "@context";
import {CreditCard, HelpOutline} from "@mui/icons-material";
import {ThemeUnitag} from "@/themes/unitagTheme";
import {useIsInViewport, useSnackbar} from "@hooks";
import AlacarteImageModule from "@/Views/Alacarte/_components/AlacarteImageModule";
import {LabelledPaper} from "@components/UI";

const {REACT_APP_API_URL} = process.env;

interface locationPlan {
    fromPlan: string;
    topUp: Boolean;
    quantity?: any;
}


export default function AlaCarte(props: any) {

    const {t} = useTranslation(['billings', 'settings', 'common']);
    const downLg = useMediaQuery(ThemeUnitag.breakpoints.down('lg'));
    const bottomSynthesisRef = useRef(null)
    const isBottomVisible = useIsInViewport(bottomSynthesisRef)
    const stripe = useStripe();
    const location = useLocation();

    const {handleError, handleSuccess} = useSnackbar()
    const {
        creditsDetails, getCreditsDetails,
        credits, getCredits,
        plans, getPlans,
        user
    } = useUser()

    const styles = {
        paperPlanWrapper: {
            backgroundColor: '#FCF8F7',
        },
        floatingActionBar: {
            display: "flex",
            position: "fixed",
            bottom: 25,
            width: "100%",
            zIndex: 50
        },
        floatingActionBarPaper: {
            width: "calc(100% - 64px)",
            px: { xs: 2, lg: 4 },
            py: 3,
            mb: { xs: "50px", xl: 0 }
        },
        yearlySwitchWrapper: {
            pt: "4px"
        },
        totalPriceBillingWrapper: {
            pt: downLg ? "14px" : "0"
        },
        plainSynthesisPaper: {
            p: 4,
            mb: 4
        }
    }

    const [loading, setLoading] = useState<boolean>(false);

    const [startPrice, setStartPrice] = useState<number>(0);
    const [currentPlan, setCurrentPlan] = useState<string>('');
    const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(false);
    // New values
    const [alreadyClient, setIsAlreadyClient] = useState<Boolean>(false);
    const [currency, setCurrency] = useState<string>('$');
    const [yearly, setYearly] = useState(false)
    // credit states
    const [qrcodesCredits, setQRCodesCredits] = useState<number | "unlimited" | undefined>(undefined)
    const [vcardsCredits, setVcardsCredits] = useState<number>(0)
    const [vcardsProductCredits, setVcardsProductCredits] = useState<number>(0)
    const [collaboratorsCredits, setCollaboratorsCredits] = useState<number>(0)
    const [domainsCredits, setDomainsCredits] = useState<number>(0)
    const [creditsOwned, setCreditsOwned] = useState<any>({
        vcards: 0,
        vcardsProduct: 0,
        collaborators: 0,
        domains: 0
    })
    // Discounts states
    const [vcardDiscounts, setVcardDiscounts] = useState([])
    // const [vcardProductDiscounts, setVcardProductDiscounts] = useState([])
    const [collaboratorsDiscounts, setCollaboratorsDiscounts] = useState([])
    // total price
    const [modulesPrice, setModulesPrice] = useState<any>({
        vcards: 0,
        users: 0,
        qrcodes: 0,
        domains: 0
    })
    // maximum credits
    const [maximumCreditsAllowed, setMaximumCreditsAllowed] = useState<any>({
        vcards: 251,
        vcardsProducts: 251,
        collaborators: 50,
        domains: 5
    })

    // plan upgrade display
    // let upgradingPlan = (currentPlan && (!!user && currentPlan !== user.account_type))
    // collaborators display
    let isEitherGoldOrPlat = (currentPlan === goldSubscription || currentPlan === platinumSubscription) ||
        (!!user && (user.account_type === goldSubscription || user.account_type === platinumSubscription))

    useEffect(() => {
        if (!creditsDetails) getCreditsDetails()
        if (!credits) getCredits()
        if (!plans) getPlans()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!!creditsDetails && !!creditsDetails.subscription) {
            setYearly(creditsDetails.subscription.interval === "yearly")
        }
    }, [creditsDetails])

    useEffect(() => {
        if (!!credits) {
            let _vcardsOwned: number = 0
            let _collabOwned: number = 0
            let _domainsOwned: number = 0

            let vcardsCreds = credits.find((cred: any) => cred.service_name === "vcards")
            if (vcardsCreds.credits_bought !== 0) _vcardsOwned = vcardsCreds.credits_bought


            let collabCreds = credits.find((cred: any) => cred.service_name === "users")
            if (collabCreds.credits_bought !== 0) _collabOwned = collabCreds.credits_bought

            let domainsCreds = credits.find((cred: any) => cred.service_name === "domains")
            if (domainsCreds.credits_bought !== 0) _domainsOwned = domainsCreds.credits_bought

            setCreditsOwned({ vcards: _vcardsOwned, collaborators: _collabOwned, domains: _domainsOwned })
        }
    }, [credits])

    // manage discounts lists when owned items are loaded
    useEffect(() => {
        calculateVcardDiscounts()
        calculateUsersDiscounts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creditsOwned])

    useEffect(() => {
        if (!currentPlan) return
        calculateQRCodesFromPlan()

        switch (currentPlan) {
            case standaloneSubscription: setMaximumCreditsAllowed({vcards: 50, vcardsProduct: 50, collaborators: 50, domains: 5}); break;
            default: setMaximumCreditsAllowed({vcards: 251, vcardsProduct: 251, collaborators: 50, domains: 5}); break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlan])

    useEffect(() => {
        if (!!location.state) {

            const _locationPlan: locationPlan = location.state as locationPlan
            const selectedPlan = _locationPlan.fromPlan
            const vcardsQuantity = _locationPlan.quantity

            setCurrentPlan(selectedPlan);

            if (!!vcardsQuantity && vcardsQuantity > 0) {
                setVcardsCredits(Number(vcardsQuantity))
            }

            // the topUp route variable means that the user is already client or does not want to upgrade
            if (!_locationPlan.topUp && !plans) getPlans().then()
            else if (_locationPlan.topUp) setIsAlreadyClient(true);
        } else {
            if (!!user && !!user.account_type && user.accountType !== freeSubscription) {
                setCurrentPlan(user.account_type)
            }
            else setCurrentPlan(standaloneSubscription)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, user])

    useEffect(() => {
        if (!!location.state) {
            const _locationPlan: locationPlan = location.state as locationPlan
            const selectedPlan = _locationPlan.fromPlan

            if (!_locationPlan.topUp && !!plans) calculatePlansPrices(selectedPlan)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans])

    // calculating stuff
    const calculateNetTotal = () => {
        let monthly: number
        let yearly: number

        monthly = modulesPrice.vcards + modulesPrice.users + modulesPrice.qrcodes + modulesPrice.domains + startPrice
        yearly = ((monthly-modulesPrice.domains) * 12 * 0.9) + (modulesPrice.domains * 12)

        return {monthly, yearly}
    }

    const calculateQRCodesFromPlan = () => {
        if (!currentPlan) return
        switch (currentPlan) {
            case standaloneSubscription: setQRCodesCredits(0); break;
            case standardSubscription: setQRCodesCredits(100); break;
            case goldSubscription: setQRCodesCredits(500); break;
            case platinumSubscription: setQRCodesCredits("unlimited"); break;
            default: setQRCodesCredits(undefined)
        }
    }

    const calculatePlansPrices = (selectedPlan: any) => {
        let _currency: string;

        switch (plans[0].currency) {
            case "eur": _currency = "€"; break;
            case "gbp": _currency = "£"; break;
            default: _currency = "$";
        }

        setCurrency(_currency);
        let _startPrice: number = 0

        switch (selectedPlan) {
            case standaloneSubscription:
                _startPrice = 0
                if (_currency === "$") _startPrice = 0
                break
            case standardSubscription:
                _startPrice = 14
                if (_currency === "$") _startPrice = 17.28
                break
            case goldSubscription:
                _startPrice = 46
                if (_currency === "$") _startPrice = 49
                break
            case platinumSubscription:
                _startPrice = 94
                if (_currency === "£") _startPrice = 83
                else if (_currency === "$") _startPrice = 99
                else if (_currency === "€") _startPrice = 94
                break
        }
        setStartPrice(_startPrice);
    }

    const calculateVcardDiscounts = () => {
        let _vcardDiscounts: any

        _vcardDiscounts = [
            { tier: 2-creditsOwned.vcards, value: returnFractionFromDiscountPercentage(7.69) },
            { tier: 11-creditsOwned.vcards, value: returnFractionFromDiscountPercentage(11.53) },
            { tier: 51-creditsOwned.vcards, value: returnFractionFromDiscountPercentage(19.23) }
        ]
        if (creditsOwned.vcards > 1 && creditsOwned.vcards < 11) _vcardDiscounts.splice(0, 1)
        else if (creditsOwned.vcards >= 11 && creditsOwned.vcards < 51 ) _vcardDiscounts.splice(0, 1)

        setVcardDiscounts(_vcardDiscounts)
    }

    const calculateUsersDiscounts = () => {
        let _usersDiscounts: any

        if (isEitherGoldOrPlat) {
            if (currentPlan === goldSubscription || (!!user && user.account_type === goldSubscription)) {
                _usersDiscounts = [
                    { tier: 3-creditsOwned.collaborators, value: returnFractionFromDiscountPercentage(4.17) },
                    { tier: 7-creditsOwned.collaborators, value: returnFractionFromDiscountPercentage(8.35) },
                    { tier: 21-creditsOwned.collaborators, value: returnFractionFromDiscountPercentage(14.61) },
                ]
            } else {
                _usersDiscounts = [
                    { tier: 3-creditsOwned.collaborators, value: returnFractionFromDiscountPercentage(2.42) },
                    { tier: 7-creditsOwned.collaborators, value: returnFractionFromDiscountPercentage(4.83) },
                    { tier: 21-creditsOwned.collaborators, value: returnFractionFromDiscountPercentage(7.24) },
                ]
            }

            if (creditsOwned.collaborators > 1 && creditsOwned.collaborators < 3) _usersDiscounts.splice(0, 1)
            else if (creditsOwned.collaborators >= 11 && creditsOwned.collaborators < 51 ) _usersDiscounts.splice(0, 2)
        }


        setCollaboratorsDiscounts(_usersDiscounts)

    }

    // checkout creation
    const createSubscription = () => {
        let subs: CheckoutSubscription[];

        if (currentPlan === user.account_type) subs = [];
        else {
            subs = [
                {
                    subscription_type: currentPlan,
                    quantity: 1
                }
            ];
        }

        if (collaboratorsCredits !== 0) {
            let subType

            if (currentPlan === platinumSubscription) subType = extraPlatinumUsersSubscription
            else subType = extraGoldUsersSubscription

            subs.push({
                subscription_type: subType,
                quantity: Number(collaboratorsCredits)
            });
        }

        if (vcardsCredits !== 0) subs.push({subscription_type: extraVCardsSubscription, quantity: Number(vcardsCredits)})
        if (vcardsProductCredits !== 0) subs.push({subscription_type: extraVcardsProductSubscription, quantity: Number(vcardsCredits)})
        if (domainsCredits !== 0) subs.push({subscription_type: extraDomainsSubscription, quantity: Number(domainsCredits)})

        let body: CreateCheckoutSessionRequest = {
            subscriptions: subs,
            recurrence: yearly ? "yearly" : "monthly"
        };

        setLoading(true);

        APIPost<CreateCheckoutSessionResponse>(REACT_APP_API_URL + "/billing/checkout_session", body).then(async (data) => {
            if (data.parsedBody !== undefined) {
                if (data.parsedBody !== null && data.parsedBody.stripe_session_id !== '') {
                    stripe?.redirectToCheckout({sessionId: data.parsedBody.stripe_session_id}).then()
                    setLoading(false)
                } else if (data.status === 500) {
                    handleError(t("Error_alacarte"))
                    setOpenPaymentDialog(false)
                    setLoading(false)
                } else {
                    if (data.parsedBody !== null && !!data.parsedBody.message) {
                        handleError(t("Error_alacarte_recurrency"))
                        setLoading(false)
                    } else if (data.status === 200) {
                        handleSuccess(t("Success"))
                        await new Promise(r => setTimeout(r, 3000));
                        window.location.href = "/";
                    } else {
                        handleError(t("Error_alacarte"))
                        setOpenPaymentDialog(false)
                        setLoading(false)
                    }
                }
            }
        }, () => { handleError(t("Error_alacarte")) });
    }

    // recurrence
    const handleRecurrenceChange = () => {
        if (yearly) setYearly(false)
        else setYearly(true)
    }

    // renders
    const renderPlanUpgrade = () => {

        if (!!currentPlan && currentPlan === standaloneSubscription) return <></>

        const renderPlan = (plan: string) => {
            switch (plan) {
                case standardSubscription: return <StandardPlan />
                case goldSubscription: return <GoldPlan />
                case platinumSubscription: return <PlatinumPlan />
            }
        }

        return (
            <>
                <Grid item xs={12} sm={10} lg={7}>
                    <LabelledPaper label={t('Alacarte_plan_chosen')} background={"#FCF8F7"} bold>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                {renderPlan(currentPlan)}
                            </Grid>
                        </Grid>
                    </LabelledPaper>
                </Grid>
            </>
        )
    }

    const renderStandaloneAlert = () => {

        if (!currentPlan || currentPlan !== standaloneSubscription) return <></>

        return (
            <Grid item xs={8}>
                <Paper variant={"outlined"} sx={{ p: 2 }}>
                    <Grid container justifyContent={"center"} spacing={2}>
                        <Grid item xs={12} md={9}>
                            <Typography variant={"body2"} sx={{ mt: 1 }}>
                                {t("alert_standalone")} <Tooltip title={`${t("no_tech_support")}`} placement={"top"} arrow><HelpOutline sx={{ mb: "-4px", fontSize: 17 }} /></Tooltip>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={3} textAlign={"right"}>
                            <Link to={"/plans"}>
                                <Button secondary large>
                                    {t("common:Upgrade")}
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    const renderVcardsSliders = () => {
        let modulesBySlider = [
            {
                name: t("vcards"),
                productName: "vCards",
                icon: <img width={"45px"} height={"auto"} src={VCardsIcon} alt={"VCards Pro"} />,
                image: VCardsImage,
                owned: creditsOwned.vcards,
                value: vcardsCredits,
                setValue: setVcardsCredits,
                setPrice: (price: number) => setModulesPrice({...modulesPrice, vcards: price}),
                basePrice: 2.6,
                maxCredits: currentPlan === standaloneSubscription ? 50-creditsOwned.vcards : 251-creditsOwned.vcards,
                maxTotal: maximumCreditsAllowed.vcards,
                discounts: vcardDiscounts,
                total: t("total_vcards"),
                features: [
                    'billings:Sync_contact_vcards',
                    'billings:Scan_vcards',
                    'billings:Share_vcards',
                    'billings:Analytics_vcards'

                ] ,
                display: creditsOwned.vcards < maximumCreditsAllowed.vcards && !!user
            },
            {
                name: t("vcards_product"),
                productName: "vCards",
                icon: <img width={"45px"} height={"auto"} src={VCardsIcon} alt={"VCards Product"} />,
                image: VCardsProductImage,
                owned: creditsOwned.vcardsProduct,
                value: vcardsProductCredits,
                setValue: setVcardsProductCredits,
                setPrice: (price: number) => setModulesPrice({...modulesPrice, vcardsProduct: price}),
                basePrice: 2.6,
                maxCredits: currentPlan === standaloneSubscription ? 50-creditsOwned.vcardsProduct : 251-creditsOwned.vcardsProduct,
                maxTotal: maximumCreditsAllowed.vcardsProduct,
                discounts: vcardDiscounts,
                total: t("total_vcards"),
                features: [
                    'billings:Sync_contact_vcards',
                    'billings:Scan_vcards',
                    'billings:Share_vcards',
                    'billings:Analytics_vcards'

                ] ,
                display: false
            },
        ]

        return (
            <Grid item xs={12} container justifyContent={"center"} spacing={3}>
                {
                    modulesBySlider.map((module: any, index: number) => {
                        if (!module.display) return <></>
                        return (
                            <Grid item xs={12} sm={10} md={6} lg={4} key={index}>
                                <AlacarteImageModule {...{module, currency, yearly, t}} />
                            </Grid>
                        )
                    })
                }
            </Grid>
        )

    }

    const renderSliders = () => {
        let modulesBySlider = [
            {
                name: t("extra_users"),
                introduction: t("extra_users_introduction"),
                icon: null,
                owned: creditsOwned.collaborators,
                value: collaboratorsCredits,
                setValue: setCollaboratorsCredits,
                setPrice: (price: number) => setModulesPrice({...modulesPrice, users: price}),
                basePrice: currentPlan === platinumSubscription ? 8.29 : 4.27,
                maxCredits: maximumCreditsAllowed.collaborators-creditsOwned.collaborators,
                discounts: collaboratorsDiscounts,
                total: t("total_collaborators"),
                features: [
                    'Extra_users',
                    'Permission_users',
                    'Working_users'
                ],
                display: isEitherGoldOrPlat
            },
            {
                name: t("domains"),
                introduction: t("domains_introduction"),
                icon: null,
                owned: creditsOwned.domains,
                value: domainsCredits,
                setValue: setDomainsCredits,
                setPrice: (price: number) => setModulesPrice({...modulesPrice, domains: price}),
                basePrice: currency === "$" ? 9.04 : 7.99,
                maxCredits: maximumCreditsAllowed.domains-creditsOwned.domains,
                discounts: [],
                total: t("total_domains"),
                display: isEitherGoldOrPlat,
                disableYearlyDiscount: true
            }
        ]

        return (
            <Grid item xs={12} container justifyContent={"center"}>
                {
                    modulesBySlider.map((module: any, index: number) => {
                        if (!module.display) return <></>
                        return (
                            <Grid item xs={12} sm={10} lg={7} key={index}><AlacarteModule {...{module, currency, yearly, t}} /></Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    const renderSynthesis = (type: string) => {

        const synthesisContent = () => {
            return (
                <Grid container justifyContent={"space-between"} columnSpacing={2}>

                    {/* Total price display */}
                    <Grid item lg={6} sx={styles.totalPriceBillingWrapper}>
                        <Typography
                            variant={downLg ? "subtitle1" : "h6"}
                            fontWeight={700}
                            component={"p"}
                            color={"primary"}
                        >
                            {t("total_price")} :&nbsp;
                            <Typography color={"textPrimary"} component={"span"} fontWeight={700}>
                                { yearly ? roundToCent(calculateNetTotal().yearly) : roundToCent(calculateNetTotal().monthly) } {currency}
                            </Typography>
                        </Typography>
                        {
                            !alreadyClient &&
                            <Typography>
                                {t("free_trial_synthesis")}
                            </Typography>
                        }
                        <Stack direction={"row"} spacing={1}>
                            <Typography variant={"body2"} fontWeight={600} sx={{ pt: 1 }}>
                                {t("plans:Pay_monthly")}
                            </Typography>
                            <Switch checked={yearly} onChange={() => setYearly(!yearly)} />
                            <Typography variant={"body2"} fontWeight={600} sx={{ pt: 1 }}>
                                {t("plans:Pay_yearly")}
                                <Typography variant={"body2"} color={"secondary"} component={"span"} sx={{ pt: 1 }}>
                                    &nbsp;&nbsp;({t("yearly_reduction")})
                                </Typography>
                            </Typography>
                        </Stack>
                    </Grid>

                    {/* Checkout button */}
                    <Grid item>
                        <Button
                            primary
                            large={!downLg}
                            startIcon={!alreadyClient ? null : <CreditCard />}
                            disabled={calculateNetTotal().monthly === 0 ||
                                (Number(vcardsCredits) + creditsOwned.vcards >= maximumCreditsAllowed.vcards ||
                                Number(collaboratorsCredits) + creditsOwned.collaborators >= maximumCreditsAllowed.users)}
                            onClick={() => setOpenPaymentDialog(true)}
                        >
                            {!alreadyClient ? t("start_free_trial") : t("proceed_with_payment")}
                        </Button>
                    </Grid>
                </Grid>
            )
        }

        if (type === "plain") {
            return (
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} md={8}>
                        <Paper variant={"outlined"} sx={styles.plainSynthesisPaper} ref={bottomSynthesisRef}>
                            {synthesisContent()}
                        </Paper>
                    </Grid>
                </Grid>

            )
        } else if (type === "floating") {
            return (
                <Container sx={styles.floatingActionBar} maxWidth={false}>
                    <Container maxWidth={"lg"}>
                        <Collapse in={!isBottomVisible}>
                            <Paper sx={styles.floatingActionBarPaper} elevation={8}>
                                {synthesisContent()}
                            </Paper>
                        </Collapse>
                    </Container>
                </Container>
            )
        }
    }

    return (
        <main>
            <Container maxWidth="xl" style={{marginTop: '50px'}}>
                <Grid container justifyContent="center" spacing={2}>

                    <Grid item xs={12}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <MaterialLink className={"link"} color="inherit" component={Link} to="/">
                                {t("Unitag_console")}
                            </MaterialLink>
                            <Typography color="textPrimary">
                                Unitag à la carte
                            </Typography>
                        </Breadcrumbs>
                    </Grid>

                    <Grid item xs={10} md={8} style={{textAlign: 'center', marginBottom: '24px'}}>
                        <Typography variant="h2" component="h1">{currentPlan !== standaloneSubscription ? "Unitag à la carte" : t("purchase_vcards")}</Typography>
                        <br/>
                        <Typography variant="body2">{currentPlan !== standaloneSubscription ? t("Alacarte_h1") : t("purchase_vcards_subtitle")}{}</Typography>
                        <br/>
                        <Divider />
                    </Grid>

                    {renderPlanUpgrade()}
                    {renderVcardsSliders()}
                    {renderSliders()}
                    {renderStandaloneAlert()}

                    <Grid item xs={12}>{renderSynthesis("plain")}</Grid>
                </Grid>
            </Container>

            {(currentPlan !== standaloneSubscription) && renderSynthesis("floating")}

            <PaymentDialog
                {...{
                    vcardsCredits, collaboratorsCredits, qrcodesCredits, domainsCredits,
                    alreadyClient,
                    user, currency,
                    handleRecurrenceChange,
                    loading
                }}
                chosenPlan={currentPlan}


                open={openPaymentDialog}
                onClose={() => setOpenPaymentDialog(false)}
                onSubscribe={createSubscription}

                pricePerMonth={calculateNetTotal().monthly}
                pricePerYear={calculateNetTotal().yearly}

                recurrence={yearly ? "yearly" : "monthly"}
            />
        </main>
    );
}

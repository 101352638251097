import React from "react"

// MUI
import {Container} from "@mui/material";
// CSS
import "./brandsCarouselStyles.css"


export default function BrandsCarousel(props: { tier:  "vcards" | "standard" | "gold" | "platinum" }) {

    const {tier} = props

    const assets_base_url: string = "https://unitag-public-assets.s3.eu-west-1.amazonaws.com/website"

    const brands = {
        standard: [
            "am-studio",
            "zenith",
            "conforama",
            "bigfoot",
            "carte-noire",
            "badet-clement",
            "zodiac-nautic",
            "les-echos",
            "vinci",
            // Repeat
            "am-studio", "zenith", "conforama", "bigfoot", "carte-noire", "badet-clement", "zodiac-nautic", "les-echos", "vinci",
            "am-studio", "zenith", "conforama", "bigfoot", "carte-noire", "badet-clement", "zodiac-nautic", "les-echos", "vinci",
            "am-studio", "zenith", "conforama", "bigfoot", "carte-noire", "badet-clement", "zodiac-nautic", "les-echos", "vinci",
            "am-studio", "zenith", "conforama", "bigfoot", "carte-noire", "badet-clement", "zodiac-nautic", "les-echos", "vinci",
        ],
        gold: [
            "royal-mansour",
            "quick",
            "hikvision",
            "credit-mutuel",
            "allianz",
            "kronenbourg",
            "warner-bros",
            // Repeat
            "royal-mansour", "quick", "hikvision", "credit-mutuel", "allianz", "kronenbourg", "warner-bros",
            "royal-mansour", "quick", "hikvision", "credit-mutuel", "allianz", "kronenbourg", "warner-bros",
            "royal-mansour", "quick", "hikvision", "credit-mutuel", "allianz", "kronenbourg", "warner-bros",
            "royal-mansour", "quick", "hikvision", "credit-mutuel", "allianz", "kronenbourg", "warner-bros",
        ],
        platinum: [
            "m-bricolage",
            "arthur-lloyd",
            "lvmh",
            "nirvana-holding",
            "cochez-y-cia",
            "CHANEL",
            // Repeat
            "m-bricolage", "arthur-lloyd", "lvmh", "nirvana-holding", "cochez-y-cia", "CHANEL",
            "m-bricolage", "arthur-lloyd", "lvmh", "nirvana-holding", "cochez-y-cia", "CHANEL",
            "m-bricolage", "arthur-lloyd", "lvmh", "nirvana-holding", "cochez-y-cia", "CHANEL",
            "m-bricolage", "arthur-lloyd", "lvmh", "nirvana-holding", "cochez-y-cia", "CHANEL",
        ]
    }

    // const _brands = [
    //     "altice", "buzzman", "CEPOVETT", "CHANEL", "cortess", "croixrouge", "Dassauld", "DECATHLON",
    //     "Deltacore", "Doopera", "Loereal", "Migros", "MKG", "Partoo", "Schneider", "SGS", "SNP", "TALIS",
    //     "Timberland", "Unilever", "UNIPAY", "vaillant", "vin bordeaux", "WB",
    //
    //     "altice", "buzzman", "CEPOVETT", "CHANEL", "cortess", "croixrouge", "Dassauld", "DECATHLON",
    //     "Deltacore", "Doopera", "Loereal", "Migros", "MKG", "Partoo", "Schneider", "SGS", "SNP", "TALIS",
    //     "Timberland", "Unilever", "UNIPAY", "vaillant", "vin bordeaux", "WB",
    // ]

    const renderCarousel = () => {

        let tierBrands: any[] = []

        switch (tier) {
            case "standard": tierBrands = brands.standard; break;
            case "gold": tierBrands = brands.gold; break;
            case "platinum": tierBrands = brands.platinum; break;
            default:
        }

        if (tierBrands.length < 1) return <></>

        return (
            <div className={"carouselRow"} key={tierBrands.length}>
                {tierBrands.map((brand: any, index: number) => (
                    <div className={"carouselItem"} key={index}>
                        <img
                            src={assets_base_url + `/landing/brands/${brand}.png`}
                            height={50}
                            width={100}
                            alt={"Brand logo"}
                            draggable={false}
                            style={{
                                maxHeight: 120,
                                height: "inherit",
                                width: "inherit",
                                maxWidth: "inherit"
                            }}
                        />
                    </div>
                ))}
            </div>
        )
    }

    return (
        <>
            <Container maxWidth={false} className={"carouselBanner"}>
                <div className={"carouselWrapper"}>
                    {renderCarousel()}
                </div>
            </Container>
        </>
    )
}

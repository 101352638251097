import React from 'react';
// MUI
import {IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {TranslateRounded} from '@mui/icons-material';
// TRANSLATION
import i18next from 'i18next';
import {useTranslation} from "react-i18next";


export default function LanguageMenu() {

    const {t} = useTranslation(['navigation', 'common']);

    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

    const changeLanguage = (lang: any) => {
        i18next.changeLanguage(lang, (_err, t) => {
            t('key');
        }).then()

        setMenuAnchor(null)
    }

    return (
        <div>
            <Tooltip title={`${t("Language")}`} arrow>
                <IconButton
                    aria-label={`${t("Language")}`}
                    aria-controls="menu-language"
                    aria-haspopup="true"
                    onClick={(e) => setMenuAnchor(e.currentTarget)}
                    color="inherit"
                >
                    <TranslateRounded />
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={menuAnchor}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={() => changeLanguage("en-EN")}>English</MenuItem>
                <MenuItem onClick={() => changeLanguage("fr-FR")}>Français</MenuItem>
                <MenuItem onClick={() => changeLanguage("es-ES")}>Español</MenuItem>
            </Menu>
        </div>
    )
}

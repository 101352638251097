import {createTheme} from '@mui/material/styles';
// in the file where you are creating the theme (invoking the function `createTheme()`)
import { Theme } from '@mui/material/styles';
import {alpha, getContrastRatio} from "@mui/material";

declare module '@mui/material/styles' {
    // noinspection JSUnusedGlobalSymbols
    interface Palette {
        free: Palette['primary']
        gold: Palette['primary']
        standard: Palette['primary']
        platinum: Palette['primary']
        enterprise: Palette["primary"]
        standalone: Palette["primary"]
    }

    // noinspection JSUnusedGlobalSymbols
    interface PaletteOptions {
        free?: PaletteOptions['primary']
        gold?: PaletteOptions['primary']
        standard?: PaletteOptions['primary']
        platinum?: PaletteOptions['primary']
        enterprise?: PaletteOptions['primary']
        standalone?: PaletteOptions["primary"]
    }
}

declare module '@mui/material/Button' {
    // noinspection JSUnusedGlobalSymbols
    interface ButtonPropsColorOverrides {
        free: true
        gold: true
        standard: true
        platinum: true
        enterprise: true
        standalone: true
    }
}

declare module '@mui/material/Typography' {
    // noinspection JSUnusedGlobalSymbols
    interface TypographyPropsColorOverrides {
        free: true
        gold: true
        standard: true
        platinum: true
        enterprise: true
        standalone: true
    }
}

declare module '@mui/icons-material' {
    // noinspection JSUnusedGlobalSymbols
    interface CommonPropsColorOverrides {
        free: true
        gold: true
        standard: true
        platinum: true
        enterprise: true
        standalone: true
    }
}

const colors = {
    free: "#adadad",
    standard: "#afc928",
    gold: "#e39d00",
    platinum: "#0077cc",
    enterprise: "#8721a1",
    standalone: "#00cc8f",
}

export const ThemeUnitag: Theme = createTheme({
    palette: {
        primary: {
            main: "#b4c74a",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#6192bd",
            contrastText: "#ffffff",
        },
        text: {
            secondary: "#525252",
        },
        standard: {
            main: colors.standard,
            light: alpha(colors.standard, 0.5),
            dark: alpha(colors.standard, 0.9),
            contrastText: getContrastRatio(alpha(colors.standard, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
        },
        gold: {
            main: colors.gold,
            light: alpha(colors.gold, 0.5),
            dark: alpha(colors.gold, 0.9),
            contrastText: getContrastRatio(alpha(colors.gold, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
        },
        platinum: {
            main: colors.platinum,
            light: alpha(colors.platinum, 0.5),
            dark: alpha(colors.platinum, 0.9),
            contrastText: getContrastRatio(alpha(colors.platinum, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
        },
        enterprise: {
            main: colors.enterprise,
            light: alpha(colors.enterprise, 0.5),
            dark: alpha(colors.enterprise, 0.9),
            contrastText: getContrastRatio(alpha(colors.enterprise, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
        },
        standalone: {
            main: colors.standalone,
            light: alpha(colors.standalone, 0.5),
            dark: alpha(colors.standalone, 0.9),
            contrastText: getContrastRatio(alpha(colors.standalone, 0.7), '#fff') > 4.5 ? '#fff' : '#111',
        }
    },
    typography: {
        h1: {
            fontFamily: 'Roboto',
            fontSize: 48,
            lineHeight: "58px",
            letterSpacing: "0.00938em",
            fontWeight: 900,
        },
        h2: {
            fontSize: 24,
            lineHeight: '34px',
            fontWeight: 400,
        },
        h5: {
            fontWeight: '500',
        }
    },
});

import React from "react"
// CSS
import '../../styles/globals.css'
import '../../styles/PrimereactOverrides.css'
// Primereact
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// MUI
import {ThemeProvider} from "@mui/material/styles";
import {ThemeUnitag} from "@/themes/unitagTheme";
// Elastic UI
import '@elastic/eui/dist/eui_theme_light.css';
import {EuiProvider} from "@elastic/eui"

const DashboardUiProvider = (props: any) => {
    return (
        <ThemeProvider theme={ThemeUnitag}>
            <EuiProvider>
                {props.children}
            </EuiProvider>
        </ThemeProvider>
    )
}

export default DashboardUiProvider
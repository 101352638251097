import React from "react"
import {CircularProgress, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function LoadingCircle() {

    const {t} = useTranslation(["common"])

    return (
        <div style={{ height: "100vh", padding: "100px", marginTop: 200 }}>
            <div style={{ margin: "auto", textAlign: "center" }}>
                <CircularProgress size={60} sx={{ mb: 3 }} />
                <Typography variant={"h5"}>{t("loading")}</Typography>
            </div>
        </div>
    )
}
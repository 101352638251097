export const styles = {
    container: {
        py: 4,
        px: 2,
        minHeight: "100vh"
    },
    divider: {
        mb: 4
    },
    buttonPaper: {
        p: 3,
        width: "100%",
        height: "100%",
        alignItems: "center",
        transition: "0.2s ease-in-out",
        "&:hover": {
            borderColor: '#6192bd',
            backgroundColor: '#f8f8f8'
        },
    },
    buttonTitle: { fontWeight: 700, my: 1 },
    gridItem: { textAlign: 'center' },
    buttonBase: { height: '100%', width: '250px' },
    disabledButton: { height: '100%', width: '250px', filter: "grayscale(100%)" },
    preview: { my: 10, textAlign: "center" },
    button: { px: 9, py: 2, mt: 4 }
}